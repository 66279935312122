import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Pagination,
  Toolbar,
  alpha,
  Collapse,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { saveAs } from "file-saver";

// icons
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Components
import MainCard from "ui-component/cards/MainCard";
import Message from "components/Snackbar/Snackbar";
import Loading from "components/Loading";
import Popup from "components/Popup";
import { MENU_OPEN } from "store/actions";
import { API, Perks_APIS } from "api/API";
import Axios from "api/Axios";
import "./style.css";
import ReusableMenu from "components/Menu";
import { PerksFilter } from "store/Data";
import { FaQrcode, FaRegFilePdf } from "react-icons/fa";
import { MdOutlineGppGood } from "react-icons/md";
import UploadPDF from "./UploadPDF";
import { replaceSymbols } from "functions/Functions";

const validationSchema = Yup.object().shape({
  wallboards: Yup.array().of(
    Yup.object().shape({
      year: Yup.mixed().required("Year  is required"),
      month: Yup.mixed().required("Month  is required"),
    })
  ),
});

const Perks = ({ ...others }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [next, setNext] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [AddAssets, setAddAssets] = useState(false);
  const [rowExpansion, setRowExpansion] = useState(
    Array(showData.length).fill(false)
  );
  const [MenuChecks, setMenuChecks] = useState([]);
  const [RWO_Type, setRwoType] = useState({
    hanger: false,
    comp: false,
    RWO: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });
  const [uploadCheck, setUploadCheck] = useState({
    pre_print_mergename_id: "",
    post_order: "",
    merged_name: "",
    batch_number: "",
  });
  const [subListingData, setSubListingData] = useState([]);
  const [uploadAssetsData, setUploadAssetsData] = useState({
    year: "",
    month: "",
    post_order: "",
    post_code: "",
    merged_name: "",
    batch_number: "",
    pre_print_id: "",
    file_name: "",
  });
  const [getFileNames, setFileNames] = useState([]);

  const [PosterAssets, setPosterAssets] = useState({});

  const [childRowExpansion, setChildRowExpansion] = useState(
    Array(subListingData.length).fill(false)
  );

  const [deleteSubListing_Data, setDeleteSublisting_Data] = useState({
    pre_print_mergename_id: "",
    post_order: "",
    batch_number: "",
    merged_name: "",
    file_name: "",
  });

  const [childListingData, setChildListingdata] = useState([]);

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const dispatch = useDispatch();
  const GetItem = localStorage.getItem("Profile_Details");
  const Details = JSON.parse(GetItem);

  const handleRowToggle = (index, prePrintID) => {
    setRowExpansion((prevRowExpansion) => {
      setChildRowExpansion(Array(subListingData.length).fill(false));
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const handleChildRowToggle = (index, prePrintID, postOrder) => {
    setChildRowExpansion((prevRowExpansion) => {
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const SubListing = async (merged_name) => {
    try {
      setIsLoading(true);
      const result = await Axios.post(Perks_APIS.Perks_Details, {
        merged_name: merged_name,
      });
      if (result.status === 200) {
        setTimeout(() => {
          setSubListingData(result.data.csvData);
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error, "Error while getting API Response");
      setIsLoading(false);
    }
  };

  const ChildListing = async (file_name) => {
    const data = {
      file_name: file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(Perks_APIS.Get_Poster_Codes, data);
      if (result.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
          setChildListingdata(result.data.data);
        }, 500);
      }
    } catch (error) {
      console.log(error, "Error while getting API Response");
      setIsLoading(false);
    }
  };

  const GetRWOFiles = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get(Perks_APIS.Perks_File_names);
      if (response.status === 200) {
        setFileNames(response.data.fileNames);
      }
    } catch (err) {
      console.log(err, "Error while getting Files");
      setIsLoading(false);
    }
  };

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const perksData = useSelector((state) => state.Data.PerksFilter);

  const handleChangePage = async (event, newPage) => {
    try {
      setPage(newPage);
      // console.log(FilterData, "filterDat")
      if (perksData && Object.keys(perksData).length > 0) {
        await handleRWO(newPage);
      } else {
        await Get_All_listings(newPage);
      }
    } catch (error) {
      console.log("Error in Pagination", error);
    }
  };

  const handleRWO = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(
        `${Perks_APIS.Perks_Listing}?page=${page}`,
        perksData
      );
      if (response.status === 200) {
        // console.log(response.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
      // }
    } catch (error) {
      console.log(error, "Hello I am an Error");
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: error.response.data.error,
      });
      setShowData([]);
    }
  };

  const Get_All_listings = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(
        `${Perks_APIS.Perks_Listing}?page=${page}`
      );
      if (response.status === 200) {
        // console.log(response.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting Listings");
      setShowData([]);
      setIsLoading(false);
    }
  };

  const handleClick = async (option, POSTER_CODE_1) => {
    // console.log(option,post,  'hi I am an option')
    if (option === "Upload PDF") {
      setAddAssets(true);
    }
    if (option === "SmartSheet Approve") {
      // console.log('smartsheet Approved')
      await SmartSheet_Approve(POSTER_CODE_1);
    }
  };

  const SmartSheet_Approve = async (POSTER_CODE_1) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(Perks_APIS.Update_SmartSheet_Status, {
        poster_code: POSTER_CODE_1,
      });
      if (response.status === 200) {
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: response?.data?.message,
          severity: "success",
        });
        await ChildListing(uploadAssetsData.file_name);
        await SubListing(uploadCheck?.merged_name);
      }
    } catch (err) {
      console.log(err, "Error while getting Files");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: err.response?.data?.message,
        severity: "error",
      });
    }
  };

  const SmartSheet = async (merged_name) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(Perks_APIS.Perks_SmartSheet, {
        merged_name,
      });
      if (response.status === 200) {
        setIsLoading(false);
        await ChildListing(uploadAssetsData?.file_name);
        await SubListing(uploadCheck?.merged_name);
        setSnackbar({
          open: true,
          message: response?.data?.message,
          severity: "success",
        });
      }
    } catch (err) {
      console.log(err, "Error while getting Files");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: err.response?.data?.message,
        severity: "error",
      });
    }
  };

  const SendQR = async (merged_name) => {
    try {
      setIsLoading(true);
      // const response = await Axios.post(Perks_APIS.Send_QR, { merged_name });
      const response = await Axios.get(
        `${Perks_APIS.Send_QR}?merged_name=${merged_name}`
      );
      if (response.status === 200) {
        setIsLoading(false);
        // console.log(response?.data, "send QR");
        const fileName = "Perks QR Code.csv";
        saveAs(new Blob([response.data]), fileName);
        // setSnackbar({
        //   open: true,
        //   message: response?.data,
        //   severity: "success",
        // });
      }
    } catch (err) {
      console.log(err, "Error while getting Files");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: err.response.data.error || err.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    Get_All_listings(page);
    GetRWOFiles();
    dispatch({ type: MENU_OPEN, id: "perks" });
  }, []);

  useEffect(() => {
    setChildListingdata((previousData) => previousData);
  }, [childListingData]);

  // console.log(getValues, "RwoType");
  return (
    <MainCard title="Perks">
      {/* <ComingSoon /> */}
      <Formik
        style={{ marginBottom: 6 }}
        initialValues={{
          fileName: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // handleRWO(values);
          try {
            if (!values.fileName) {
              setSnackbar({
                open: true,
                severity: "warning",
                message: "Please select the File Name",
              });
            } else {
              const data = {
                file_name: values.fileName,
              };
              dispatch(PerksFilter(data));
              setIsLoading(true);
              const response = await Axios.post(
                `${Perks_APIS.Perks_Listing}?page=${page}`,
                data
              );
              if (response.status === 200) {
                // console.log(response.data, "response");
                setShowData(response.data.data);
                setNext(response?.data);
                setIsLoading(false);
              }
            }
          } catch (error) {
            console.log(error, "Hello I am an Error");
            setIsLoading(false);
            setSnackbar({
              open: true,
              severity: "error",
              message: error.response.data.error,
            });
            setShowData([]);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          setYear,
          resetForm,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            {/*  */}

            <div className="xyz">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                sx={{ alignItems: "center", marginBottom: 5 }}
              >
                <Grid item xs={0.8}>
                  <Typography variant="subtitle1">Filter by:</Typography>
                </Grid>

                {/* Select File */}
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    // error={Boolean(touched.year && errors.year)}
                    // sx={{ ...theme.typography.customInput }}
                  >
                    <Autocomplete
                      autoHighlight
                      options={getFileNames || []}
                      getOptionLabel={(option) => option || ""}
                      // key={(option) => option.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select File"
                          variant="outlined"
                        />
                      )}
                      name="fileName"
                      value={
                        values.fileName
                          ? getFileNames.find(
                              (item) => item === values.fileName
                            )
                          : ""
                      }
                      onChange={(_, newValue) => {
                        setFieldValue(`fileName`, newValue ? newValue : "");
                      }}
                      noOptionsText="No Results Found"
                    />
                    {touched.fileName && errors.fileName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-year"
                      >
                        {errors.fileName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Buttons */}
                <Grid item xs={3} style={{ display: "flex", gap: "10px" }}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#15223F",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                    // onClick={() => {
                    //   dispatch(ViewAllFilter(undefined));
                    // }}
                  >
                    Filter
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#C62828",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                    onClick={async () => {
                      dispatch(PerksFilter([]));
                      setFieldValue(`fileName`, "");
                      setPage(1);
                      Get_All_listings(1);
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Formik>

      <TableContainer component={Paper} style={{ border: "1px solid black" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }} className="headings">
                #
              </TableCell>
              <TableCell className="headings">Perks Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData.length > 0 ? (
              showData.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell
                        style={{ textAlign: "center" }}
                        padding="checkbox"
                      >
                        {/* {serialNumber(page, index)} */}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            handleRowToggle(index, item.pre_print_id);
                            setUploadAssetsData({
                              ...uploadAssetsData,
                              month: item.month,
                              year: item.year,
                              merged_name: item.merged_name,
                              // file_name: item.file_name,
                              // batch_number:item.batch_number
                            });
                          }}
                        >
                          {
                            // listingOpen
                            rowExpansion[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon
                                onClick={async () => {
                                  await SubListing(item.merged_name);
                                  // console.log(item.pre_print_mergename_id, 'hello this')
                                  setUploadCheck({
                                    ...uploadCheck,
                                    merged_name: item.merged_name,
                                    // pre_print_mergename_id: item.pre_print_id,
                                  });
                                }}
                              />
                            )
                          }
                        </IconButton>
                      </TableCell>
                      <TableCell className="common">
                        {item?.merged_name}

                        <br />
                        <div className="allbuttons components">
                          {/* <Button
                            size="small"
                            variant="contained"
                            style={{
                              background: "#15223F",
                              padding: "6px 10px",
                              marginTop: 10,
                            }}
                            startIcon={<MdOutlineGppGood color="white" />}
                            onClick={() => {
                              SmartSheet(item.merged_name);
                            }}
                          >
                            Wallboard Confirmation
                          </Button> */}
                          <Button
                            size="small"
                            variant="contained"
                            style={{
                              background: "#15223F",
                              padding: "6px 10px",
                              marginTop: 10,
                            }}
                            startIcon={<FaQrcode />}
                            onClick={() => SendQR(item.merged_name)}
                          >
                            Send QR Codes
                          </Button>
                        </div>

                        <br />
                        <Collapse
                          in={rowExpansion[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Details
                            </Typography>
                            <Table
                              size="small"
                              aria-label="purchases"
                              className="dataTable"
                            >
                              <TableHead>
                                <TableRow key={index}>
                                  <TableCell>#</TableCell>
                                  <TableCell>Month/Year</TableCell>
                                  <TableCell>Print order</TableCell>
                                  <TableCell>RWO/Batch Number</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Final Status
                                  </TableCell>
                                  {/* <TableCell>Actions</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {subListingData.length > 0 ? (
                                  subListingData.map((item, index) => (
                                    <>
                                      <TableRow className="child" key={index}>
                                        <TableCell>
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            // onClick={() => setListingOpen(!listingOpen)}
                                            onClick={() =>
                                              handleChildRowToggle(index)
                                            }
                                          >
                                            {
                                              // listingOpen
                                              childRowExpansion[index] ? (
                                                <KeyboardArrowUpIcon />
                                              ) : (
                                                <KeyboardArrowDownIcon
                                                  onClick={async () => {
                                                    await ChildListing(
                                                      item.file_name
                                                    );
                                                    // setUploadCheck({
                                                    //   ...uploadCheck,
                                                    //   file_name: item.file_name,
                                                    // });
                                                    setUploadAssetsData({
                                                      ...uploadAssetsData,
                                                      // post_order:
                                                      //   item.post_order,
                                                      // batch_number:
                                                      //   item.batch_number,
                                                      file_name: item.file_name,
                                                    });
                                                  }}
                                                />
                                              )
                                            }
                                          </IconButton>
                                        </TableCell>
                                        <TableCell>
                                          {item?.merged_name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {item?.post_order}
                                        </TableCell>
                                        <TableCell>
                                          {item?.batch_number}
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          {/* {item?.batch_number} */}
                                          {item.perkCheck === true ? (
                                            <p className="approve">Completed</p>
                                          ) : (
                                            <p className="pending">Pending</p>
                                          )}
                                        </TableCell>
                                        {/* <TableCell>
                                          <ReusableMenu
                                            APICall="no"
                                            // data={{
                                            //   state: {
                                            //     prePrintMergenameId:
                                            //       item.pre_print_mergename_id,
                                            //     post_order: item.post_order,
                                            //     batch_number: item.batch_number,
                                            //     merged_name: item.merged_name,
                                            //     file_name: item.file_name,
                                            //   },
                                            // }}
                                            setMenuChecks={setMenuChecks}
                                            // height="180px"
                                            options={[
                                              "Final Approve",
                                            ]}
                                          
                                            handleOption={(option) => {
                                              handleClick(
                                                option
                                              );
                                            }}
                                          />

                                          <Popup
                                            title={`Are you sure you want to delete the record?`}
                                            open={deleteSubListing}
                                            // overflowY="auto"
                                            // height="365px"
                                            width={"430px"}
                                            content={
                                              <>
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    marginTop: 10,
                                                  }}
                                                >
                                                  <Button
                                                    size="large"
                                                    variant="contained"
                                                    style={{
                                                      background: "#C62828",
                                                      marginRight: "10px",
                                                    }}
                                                    onClick={() =>
                                                      setDeleteSubListing(false)
                                                    }
                                                  >
                                                    Close
                                                  </Button>
                                                  <Button
                                                    size="large"
                                                    variant="contained"
                                                    style={{
                                                      background: "#1d213e",
                                                      marginRight: "15px",
                                                    }}
                                                    onClick={async () => {
                                                      //   console.log(
                                                      //     deleteSubListing_Data.batch_number
                                                      //   );
                                                      // }
                                                      await delete_SubListing(
                                                        deleteSubListing_Data.pre_print_mergename_id,
                                                        deleteSubListing_Data.post_order,
                                                        deleteSubListing_Data.merged_name,
                                                        deleteSubListing_Data.batch_number,
                                                        deleteSubListing_Data.file_name
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </Button>
                                                </div>
                                              </>
                                            }
                                          />
                                        </TableCell> */}
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          style={{ borderBottom: 0 }}
                                          colSpan={5}
                                        >
                                          <div className="subchild">
                                            <Collapse
                                              in={childRowExpansion[index]}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box sx={{ margin: 1 }}>
                                                <Typography
                                                  variant="h6"
                                                  gutterBottom
                                                  component="div"
                                                >
                                                  Sub Details
                                                </Typography>
                                                <div
                                                  size="small"
                                                  aria-label="purchases"
                                                >
                                                  <Table className="dataTable">
                                                    <TableHead>
                                                      <TableRow key={index}>
                                                        <TableCell>
                                                          Poster code
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          SmartSheet Status
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          FTP Status
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Actions
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {childListingData.length >
                                                      0 ? (
                                                        childListingData.map(
                                                          (item, index) => (
                                                            <TableRow
                                                              key={index}
                                                            >
                                                              <TableCell>
                                                                {
                                                                  replaceSymbols(item.POSTER_CODE_1)
                                                                }
                                                              </TableCell>
                                                              <TableCell>
                                                                {item?.smartsheet_status ===
                                                                "1" ? (
                                                                  <p className="approve">
                                                                    Approved
                                                                  </p>
                                                                ) : (
                                                                  item?.smartsheet_status ===
                                                                    "0" ||
                                                                  0 ||
                                                                  null ||
                                                                  ("null" && (
                                                                    <p className="pending">
                                                                      Pending
                                                                    </p>
                                                                  ))
                                                                )}
                                                              </TableCell>
                                                              <TableCell>
                                                                {item?.ftp_status ===
                                                                "1" ? (
                                                                  <p className="approve">
                                                                    Completed
                                                                  </p>
                                                                ) : (
                                                                  <p className="pending">
                                                                    Pending
                                                                  </p>
                                                                )}
                                                              </TableCell>

                                                              <TableCell
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <ReusableMenu
                                                                  APICall="no"
                                                                  options={[
                                                                    "Upload PDF",
                                                                    "SmartSheet Approve",
                                                                  ]}
                                                                  handleOption={(
                                                                    option
                                                                  ) => {
                                                                    setPosterAssets(
                                                                      {
                                                                        POSTER_CODE_1:
                                                                          item.POSTER_CODE_1,
                                                                      }
                                                                    );
                                                                    handleClick(
                                                                      option,
                                                                      item.POSTER_CODE_1
                                                                    );
                                                                  }}
                                                                  YourSelection={
                                                                    item?.smartsheet_status ===
                                                                    "1"
                                                                      ? [
                                                                          "SmartSheet Approve",
                                                                        ]
                                                                      : []
                                                                  }
                                                                />
                                                              </TableCell>
                                                            </TableRow>
                                                          )
                                                        )
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            colSpan={4}
                                                          >
                                                            Record Not Found
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </div>
                                              </Box>
                                            </Collapse>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      style={{ textAlign: "center" }}
                                      colSpan={5}
                                    >
                                      Record Not Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={2}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Popup
        title="Please Upload the PDF"
        open={AddAssets}
        overflowY="auto"
        // height="480px"
        content={
          <>
            <UploadPDF
              close={() => setAddAssets(false)}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setSnackbar={setSnackbar}
              PosterAssets={PosterAssets}
              ChildListing={ChildListing}
              uploadAssetsData={uploadAssetsData}
              SubListing={SubListing}
              uploadCheck={uploadCheck}
            />
          </>
        }
      />

      {showData?.length > 0 && next?.totalPages > 1 && (
        <Pagination
          count={next?.totalPages}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          className="paging"
          sx={{
            button: { mt: 2 },
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        />
      )}
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default Perks;
