import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Pagination,
  Toolbar,
  alpha,
  Collapse,
  IconButton,
  Box,
  Tooltip,
  PaginationItem,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { saveAs } from 'file-saver';

// icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FaFilePdf, FaGreaterThan } from 'react-icons/fa6';
import { FiPlusCircle } from 'react-icons/fi';
import { IoEye } from 'react-icons/io5';
import { MdDelete, MdOutlineCopyright } from 'react-icons/md';

// Components
import MainCard from 'ui-component/cards/MainCard';
import Message from 'components/Snackbar/Snackbar';
import Loading from 'components/Loading';
import Popup from 'components/Popup';
import PrePrintForm from './PrePrintForm';
import { monthss } from './Fields';
import { MENU_OPEN } from 'store/actions';
import { API } from 'api/API';
import Axios from 'api/Axios';
import './style.css';
import UploadAssets from './Upload Assets/UploadAssets';
import ReusableMenu from 'components/Menu';
import Qr from './Add QR/Qr';
import { PrePrintListingFilter } from 'store/Data';
import axios from 'axios';

const validationSchema = Yup.object().shape({
  wallboards: Yup.array().of(
    Yup.object().shape({
      year: Yup.mixed().required('Year  is required'),
      month: Yup.mixed().required('Month  is required'),
    })
  ),
});

const Preprint = ({ ...others }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [next, setNext] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteListing, setDeleteListing] = useState(false);
  const [deleteSubListing, setDeleteSubListing] = useState(false);
  const [rowExpansion, setRowExpansion] = useState(Array(showData.length).fill(false));
  const [MenuChecks, setMenuChecks] = useState([]);
  const [RWO_Type, setRwoType] = useState({
    hanger: false,
    comp: false,
    RWO: false,
  });
  const [getValues, setValues] = useState({
    month: '',
    year: '',
    printid: '',
    print_order: '',
    batch_number: '',
  });
  const [printData, setPrintData] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    // severity: "",
  });
  const [uploadCheck, setUploadCheck] = useState({
    pre_print_mergename_id: '',
    post_order: '',
    merged_name: '',
    batch_number: '',
  });
  const [subListingData, setSubListingData] = useState([]);
  const [assetsData, setassetsData] = useState([]);
  const [uploadAssetsData, setUploadAssetsData] = useState({
    year: '',
    month: '',
    post_order: '',
    post_code: '',
    merged_name: '',
    batch_number: '',
    pre_print_id: '',
    file_name: '',
  });

  const [childRowExpansion, setChildRowExpansion] = useState(
    Array(subListingData.length).fill(false)
  );

  const [uploadAsset, setUploadAsset] = useState(false);
  const [addQR, setAddQR] = useState(false);
  const [deleteSubListing_Data, setDeleteSublisting_Data] = useState({
    pre_print_mergename_id: '',
    post_order: '',
    batch_number: '',
    merged_name: '',
    file_name: '',
  });

  const [childListingData, setChildListingdata] = useState([]);

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: snackbar.severity,
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const years = ["2021", "2022", "2023", "2024"];
  const years = ['2024', '2025'];
  const GetItem = localStorage.getItem('Profile_Details');
  const Details = JSON.parse(GetItem);

  const handleRowToggle = (index, prePrintID) => {
    setRowExpansion((prevRowExpansion) => {
      setChildRowExpansion(Array(subListingData.length).fill(false));
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const handleChildRowToggle = (index, prePrintID, postOrder) => {
    setChildRowExpansion((prevRowExpansion) => {
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const SubListing = async (prePrintID) => {
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Sublisting_PrePrint, {
        pre_print_mergename_id: prePrintID,
      });
      if (result.status === 200) {
        setTimeout(() => {
          setSubListingData(result.data.csvData);
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
    }
  };

  const ChildListing = async (
    pre_print_mergename_id,
    post_order,
    merged_name,
    batch_number,
    file_name
  ) => {
    const data = {
      pre_print_mergename_id:
        pre_print_mergename_id === undefined
          ? uploadCheck.pre_print_mergename_id
          : pre_print_mergename_id,
      post_order: post_order === undefined ? uploadCheck.post_order : post_order,
      merged_name: merged_name === undefined ? uploadCheck.merged_name : merged_name,
      batch_number: batch_number === undefined ? uploadCheck.batch_number : batch_number,
      file_name: batch_number === undefined ? uploadAssetsData.file_name : file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.ChildListing_Preprint, data);
      if (result.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
          setChildListingdata(result.data.data);
        }, 500);
        return result.data.data;
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
    }
  };

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const handleChangePage = async (event, newPage) => {
    try {
      setPage(newPage);
      // console.log(FilterData, "filterDat")
      await Get_All_listings(newPage);
    } catch (error) {
      console.log('Error in Pagination', error);
    }
  };

  const handleRWO = async (values) => {
    try {
      if (!values.year || values.year.length === 0) {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Please select the Year',
        });
      } else if (!values.month || values.month.length === 0) {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Please select the Month',
        });
      } else {
        const data = {
          year: values.year,
          month: values.month,
          merged_name: `${values.month} ${values.year}`,
        };
        setRwoType({ RWO: true });
        setOpen(true);
      }
    } catch (error) {
      console.log(error, 'Hello I am an Error');
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.response.data.error,
      });
    }
  };

  const Get_All_listings = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(`${API.PrePrintListings}?page=${page}`);
      if (response.status === 200) {
        // console.log(response.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while getting Listings');
      setShowData([]);
      setIsLoading(false);
    }
  };

  const addNew = (month, year, printid) => {
    // console.log(month, year, "hello");
    setValues({ month: month, year: year, printid: printid });
    setOpen1(true);
    setRwoType({ RWO: true });
  };

  const delete_SubListing = async (merge_id, post_order, merged_name, batch_number, file_name) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Single_Sub_Listing_Delete, {
        post_order: post_order,
        // pre_print_merge_id: merge_id,
        merged_name: merged_name,
        batch_number,
        file_name,
      });
      if (response.status === 200) {
        // console.log(response.data, "response");
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: 'success',
        });
        await Get_All_listings(page);
        await SubListing(merge_id);
        setDeleteSubListing(false);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while getting API response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: err.response.data.error || err.response.data.message,
        severity: 'error',
      });
    }
  };

  const delete_Listing = async (merge_id, merged_name, file_name) => {
    try {
      const response = await Axios.post(API.Listing_Delete, {
        // pre_print_merge_id: merge_id,
        merged_name: merged_name,
        file_name,
      });
      if (response.status === 200) {
        // console.log(response.data, "response");
        await SubListing(merge_id);
        await Get_All_listings(page);
        setSnackbar({
          open: true,
          severity: 'success',
          message: response.data.message,
        });
        setDeleteListing(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, 'Error while getting API response');
    }
  };

  const handleClick = async (
    option,
    POSTER_CODE_1,
    pre_print_mergename_id,
    post_order,
    file_name,
    month,
    year,
    PO,
    batch_number,
    merged_name
  ) => {
    // console.log(month,
    //   year,
    //   PO,
    //   batch_number,file_name,  'hi I am an option')
    if (option === 'Upload Assets') {
      setUploadAssetsData({ ...uploadAssetsData, post_code: POSTER_CODE_1 });
      setUploadAsset(true);
    }
    if (option === 'Add QR') {
      setUploadAssetsData({ ...uploadAssetsData, post_code: POSTER_CODE_1 });
      setAddQR(true);
    }
    if (option === 'View QR Assets') {
      setUploadAssetsData({ ...uploadAssetsData, post_code: POSTER_CODE_1 });
      navigate('/prePrint/view-assets', {
        state: { uploadAssetsData, post_code: POSTER_CODE_1 },
      });
    }
    if (option === 'Merge Printables') {
      // console.log("merge printables");
      const post_code = POSTER_CODE_1;
      await MergePrintables(uploadAssetsData, post_code);
    }
    if (option === 'Delete PO') {
      setDeleteSubListing(true);
      setDeleteSublisting_Data({
        batch_number: batch_number,
        post_order: PO,
        pre_print_mergename_id: pre_print_mergename_id,
        merged_name: merged_name,
        file_name: file_name,
      });
    }
    if (option === 'View RWO (PO)') {
      navigate(`listing/${pre_print_mergename_id}/${post_order}/?${file_name}`, {
        state: { batch_number: batch_number },
      });
      // dispatch(PrePrintListingFilter({ file_name }));
    }
    if (option === 'Upload Comps') {
      setOpen1(true);
      setRwoType({ comp: true });
      setValues({
        month: month,
        year: year.toString(),
        printid: pre_print_mergename_id,
        print_order: post_order,
        batch_number: batch_number,
      });
    }
    if (option === 'Upload Hanger') {
      setOpen1(true);
      setRwoType({ hanger: true });
      setValues({
        month: month,
        year: year.toString(),
        printid: pre_print_mergename_id,
        print_order: post_order,
        batch_number: batch_number,
      });
    }
    if (option === 'Merge Comps') {
      await MergePrintables_Hanger_Comp(PO, 'comp', merged_name, pre_print_mergename_id, file_name);
    }
    if (option === 'Merge Hanger') {
      await MergePrintables_Hanger_Comp(
        PO,
        'hanger',
        merged_name,
        pre_print_mergename_id,
        file_name
      );
    }
    if (option === 'Export Merged RWO') {
      const post_code = POSTER_CODE_1;
      await final_concat(uploadAssetsData, PO, batch_number);
    }
    if (option === 'Final Approve') {
      await Final_approve(PO, batch_number, merged_name, file_name);
    }
    if (option === 'Export Merged Comps') {
      await Export_Comps_or_Hangers(PO, batch_number, merged_name, file_name, 'comp');
    }
    if (option === 'Export Merged Hanger') {
      await Export_Comps_or_Hangers(PO, batch_number, merged_name, file_name, 'hanger');
    }
    if (option === 'Export Merged Rolled') {
      await Export_Comps_or_Hangers(PO, batch_number, merged_name, file_name, 'rolled');
    }
    if (option === 'Merge RWO') {
      setUploadAssetsData({
        ...uploadAssetsData,
        post_order: PO,
        merged_name: merged_name,
        batch_number: batch_number,
        file_name,
      });
      await Merge_RWO(PO, merged_name, batch_number, file_name);
    }
    if (option === 'Merge Rolled') {
      await MergePrintables_Hanger_Comp(
        PO,
        'rolled',
        merged_name,
        pre_print_mergename_id,
        file_name
      );
    }
  };

  const Merge_RWO = async (PO, merged_name, batch_number, file_name) => {
    // console.log(uploadAssetsData, 'hello ASSETS')
    const data = await ChildListing(
      uploadAssetsData.pre_print_id,
      PO,
      merged_name,
      batch_number,
      file_name
    );
    // console.log(data.map((item)=> item.POSTER_CODE_1), 'hello I am the data')
    const Poster_Codes = data.map((item) => item.POSTER_CODE_1);
    const values = {
      file_name: file_name,
      POSTER_CODE_1: Poster_Codes,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Merge_PO_data, values);
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        setSnackbar({
          open: true,
          message: result.data.message,
          severity: 'success',
        });
      }
    } catch (error) {
      console.log(error, 'Error while getting Merge RWO API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response.data.message || error.message,
        severity: 'error',
      });
    }
  };

  const Export_Comps_or_Hangers = async (PO, batch_number, merged_name, file_name, type) => {
    const values = {
      post_order: PO,
      batch_number: batch_number,
      merged_name: merged_name,
      file_name: file_name,
      type: type,
    };
    const APIURL =
      `${API.Export_Merge_Hanger_comp_Data}?` +
      `${merged_name ? `merged_name=${merged_name}&` : ''}` +
      `${PO ? `post_order=${PO}&` : ''}` +
      `${batch_number ? `batch_number=${batch_number}&` : ''}` +
      `${type ? `type=${type}` : ''}`;
    try {
      setIsLoading(true);
      // const result = await Axios.post(API.Export_Merge_Hanger_comp_Data, values);
      const result = await Axios.get(APIURL);
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        const fileName =
          type === 'comp'
            ? 'QRCode_PDF_Comps.csv'
            : type === 'hanger'
            ? 'QRCode_PDF_Hanger.csv'
            : 'QRCode_PDF_Rolled.csv';
        saveAs(new Blob([result.data]), fileName);
      }
    } catch (error) {
      console.log(error, 'Error while Merge Printable API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response.data.message || error.response.data.error || error.response.data,
        severity: 'error',
      });
    }
  };

  const Final_approve = async (PO, batch_number, merged_name, file_name) => {
    const values = {
      post_order: PO,
      batch_number: batch_number,
      merged_name: merged_name,
      file_name: file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Pre_Print_Final_Approve, values);
      if (result.status === 200) {
        setIsLoading(false);
        console.log('result', result.data);

        setSnackbar({
          open: true,
          message: result.data.msg,
          severity: 'success',
        });
      }
    } catch (error) {
      console.log(error, 'Error while Merge Printable API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response.data.message || error.response.data.error || error.response.data,
        severity: 'error',
      });
    }
  };

  const MergePrintables_Hanger_Comp = async (
    post_order,
    type,
    merged_name,
    pre_print_mergename_id,
    file_name
  ) => {
    const values = {
      pre_print_mergename_id: pre_print_mergename_id,
      post_order: post_order,
      merged_name: merged_name,
      type: type,
      // file_name: type=== 'comp' ?  `${file_name} Comps and Letters` : type === 'hanger' ? `${file_name} Hangers` :null,
      file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Hanger_Comp_Merge, values);
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        Get_All_listings(1);

        setSnackbar({
          open: true,
          message: result.data.message,
          severity: 'success',
        });
      }
    } catch (error) {
      console.log(error, 'Error while Merge Printable API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response.data.message || error.response.data.error || error.response.data,
        severity: 'error',
      });
    }
  };

  const MergePrintables = async (uploadAssetsData, post_code) => {
    const data = {
      post_order: uploadAssetsData.post_order,
      merged_name: uploadAssetsData.merged_name,
      // batch_number: uploadAssetsData.batch_number,
      file_name: uploadAssetsData.file_name,
      POSTER_CODE_1: post_code,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Merge_PO_data, data);
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        setSnackbar({
          open: true,
          message: result.data.message,
          severity: 'success',
        });
      }
    } catch (error) {
      console.log(error, 'Error while Merge Printable API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response.data.message,
        severity: 'error',
      });
    }
  };

  const final_concat = async (uploadAssetsData, post_code, batch_number) => {
    const data = {
      post_order: post_code,
      merged_name: uploadAssetsData.merged_name,
      batch_number: batch_number,
      // POSTER_CODE_1: post_code,
      file_name: uploadAssetsData.file_name,
    };
    try {
      setIsLoading(true);
      const result = await axios.post(API.Export_Merged_RWO, data, {
        responseType: 'blob', // Tell Axios to expect a binary response
      });
      if (result.status === 200) {
        setIsLoading(false);
        const fileName = 'Assets.zip';
        saveAs(new Blob([result.data]), fileName);
      }
    } catch (error) {
      console.log(error, 'Error while getting API response');
      setIsLoading(false);
      // Check if the error response contains a Blob
      if (error.response && error.response.data instanceof Blob) {
        // Convert Blob to text
        const errorText = await error.response.data.text();
        // Optionally parse it as JSON if the server returns a structured error
        const errorJson = JSON.parse(errorText);
        // Display the error message
        setSnackbar({
          open: true,
          message: errorJson.error || errorJson.message || 'An error occurred',
          severity: 'error',
        });
      }
    }
  };

  const getYourSelection = (MenuChecks) => {
    const selection = [];

    // Conditions to add items based on the status
    if (!MenuChecks.MergedRWOstatus)
      selection.push('View RWO (PO)', 'Merge RWO', 'Export Merged RWO');
    if (!MenuChecks.compsStatus) selection.push('Merge Comps', 'Export Merged Comps');
    if (!MenuChecks.hangerStatus) selection.push('Merge Hanger', 'Export Merged Hanger');
    if (!MenuChecks.Rolledstatus) selection.push('Merge Rolled', 'Export Merged Rolled');

    return selection;
  };

  useEffect(() => {
    Get_All_listings(page);
    setChildRowExpansion(Array(subListingData.length).fill(false));
    setRowExpansion(Array(showData.length).fill(false));
    dispatch({ type: MENU_OPEN, id: 'preprint' });
  }, []);

  useEffect(() => {
    setChildListingdata((previousData) => previousData);
  }, [childListingData]);

  // console.log(getValues, "RwoType");
  return (
    <MainCard title="Pre Print">
      {/* <ComingSoon /> */}
      {/* <Formik
        style={{ marginBottom: 6 }}
        initialValues={{
          month: "",
          year: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleRWO(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          setYear,
          resetForm,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            <div className="xyz">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                sx={{ alignItems: "center", marginBottom: 5 }}
              >
                <Grid item columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                  <Typography variant="subtitle1">RWO:</Typography>
                </Grid>

                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      autoHighlight
                      options={years || []}
                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Year"
                          variant="outlined"
                          post_order
                        />
                      )}
                      name="year"
                      value={
                        values.year
                          ? years.find((item) => item === values.year)
                          : null
                      }
                      onChange={(_, newValue) => {
                        if (newValue?.length === 0 || newValue === null) {
                          setFieldValue(`month`, []);
                        }
                        setFieldValue(`year`, newValue ? newValue : []);
                      }}
                      noOptionsText="No Results Found"
                    />
                    {touched.year && errors.year && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-year"
                      >
                        {errors.year}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      autoHighlight
                      // disabled={
                      //   values?.year?.length === 0 || values?.year === null
                      //     ? true
                      //     : false
                      // }
                      options={monthss || []}
                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Month"
                          variant="outlined"
                        />
                      )}
                      name="month"
                      value={
                        values.month
                          ? monthss.find((item) => item === values.month)
                          : null
                      }
                      onChange={(_, newValue) => {
                        setFieldValue(`month`, newValue);
                      }}
                      // onFocus={() => Get_Wallboard_Months(values.year)}
                      noOptionsText="No Results Found"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={5} style={{ display: "flex", gap: "10px" }}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#15223F",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                  >
                    RWO
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#C62828",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                    onClick={() => {
                      resetForm();
                      setFieldValue("year", "");
                    }}
                  >
                    Clear
                  </Button>
                  {/* <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#15223F",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                    onClick={() => {
                      // navigate(`listing/2/rwo?viewall`, {state:{type:'viewall'}});
                      navigate(`listing/2/rwo?View%20All`);

                      // resetForm();
                      // setFieldValue("year", "");
                    }}
                  >
                    View All RWO's
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Popup
              title="Please enter the details"
              open={open}
              overflowY="auto"
              // height="365px"
              content={
                <>
                  <PrePrintForm
                    setIsLoading={setIsLoading}
                    uploadAssetsData={uploadAssetsData}
                    isLoading={isLoading}
                    close={() => setOpen(false)}
                    value={values}
                    setSnackbar={setSnackbar}
                    printData={printData}
                    apiCall="Yes"
                    Get_All_listings={Get_All_listings}
                    page={page}
                    RWO_Type={RWO_Type}
                    RowClose={() =>
                      setRowExpansion(Array(showData.length).fill(false))
                    }
                  />
                </>
              }
            />
          </form>
        )}
      </Formik> */}

      <TableContainer component={Paper} style={{ border: '1px solid black' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }} className="headings">
                #
              </TableCell>
              <TableCell className="headings">Pre Print Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData.length > 0 ? (
              showData.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: 'center' }} padding="checkbox">
                        {/* {serialNumber(page, index)} */}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            handleRowToggle(index, item.pre_print_id);
                            setUploadAssetsData({
                              ...uploadAssetsData,
                              month: item.month,
                              year: item.year,
                              merged_name: item.merged_name,
                              pre_print_id: item.pre_print_id,
                              // file_name: item.file_name,
                              // batch_number:item.batch_number
                            });
                          }}
                        >
                          {
                            // listingOpen
                            rowExpansion[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon
                                onClick={async () => {
                                  await SubListing(item.pre_print_id);
                                  // console.log(item.pre_print_mergename_id, 'hello this')
                                  setUploadCheck({
                                    ...uploadCheck,
                                    pre_print_mergename_id: item.pre_print_id,
                                  });
                                }}
                              />
                            )
                          }
                        </IconButton>
                      </TableCell>
                      <TableCell className="common">
                        {item?.merged_name}
                        {/* <div className="delete">
                          <Tooltip title="Delete">
                            <button
                              style={{ background: "unset", border: "none" }}
                              onClick={() => {
                                setUploadAssetsData({
                                  ...uploadAssetsData,
                                  merged_name: item.merged_name,
                                  pre_print_id: item.pre_print_id,
                                });
                                setDeleteListing(true);
                              }}
                            >
                              <MdDelete
                                style={{ cursor: "pointer" }}
                                color="#c62828"
                                fontSize={18}
                              />
                            </button>
                          </Tooltip>

                          <Popup
                            title={`Are you sure you want to delete the record?`}
                            open={deleteListing}
                            // overflowY="auto"
                            // height="365px"
                            width={"430px"}
                            content={
                              <>
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginTop: 10,
                                  }}
                                >
                                  <Button
                                    size="large"
                                    variant="contained"
                                    style={{
                                      background: "#C62828",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => {
                                      setDeleteListing(false);
                                    }}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    size="large"
                                    variant="contained"
                                    style={{
                                      background: "#1d213e",
                                      marginRight: "15px",
                                    }}
                                    onClick={
                                      async () => {
                                        // console.log(uploadAssetsData.merged_name,'merged_name')
                                        await delete_Listing(
                                          uploadAssetsData?.pre_print_id,
                                          uploadAssetsData?.merged_name,
                                          uploadAssetsData.file_name
                                        );
                                      }

                                      // (item.pre_print_id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </>
                            }
                          />
                        </div> */}
                        {/* <br /> */}
                        {/* <div className="allbuttons components">
                          <Button
                            size="small"
                            variant="contained"
                            style={{
                              background: "#15223F",
                              padding: "6px 10px",
                              marginTop: 10,
                            }}
                            startIcon={<FiPlusCircle />}
                            onClick={() => {
                              addNew(item.month, item.year, item.pre_print_id);
                            }}
                          >
                            Add New
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            style={{
                              background: "#15223F",
                              padding: "6px 10px",
                              marginTop: 10,
                            }}
                            startIcon={<IoEye />}
                            onClick={() =>
                              navigate(`listing/${item?.pre_print_id}/rwo`)
                            }
                          >
                             View RWO's 
                            RWO Month View
                          </Button>
                        </div> */}

                        <br />

                        <Collapse in={rowExpansion[index]} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                              Details
                            </Typography>
                            <Table size="small" aria-label="purchases" className="dataTable">
                              <TableHead>
                                <TableRow key={index}>
                                  <TableCell>#</TableCell>
                                  <TableCell>Month/Year</TableCell>
                                  <TableCell>Print order</TableCell>
                                  <TableCell>RWO\Batch Number</TableCell>
                                  <TableCell>Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {subListingData.length > 0 ? (
                                  subListingData.map((item, index) => (
                                    <>
                                      <TableRow className="child" key={index}>
                                        <TableCell>
                                          {/* <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            // onClick={() => setListingOpen(!listingOpen)}
                                            onClick={() =>
                                              handleChildRowToggle(index)
                                            }
                                          >
                                            {
                                              // listingOpen
                                              childRowExpansion[index] ? (
                                                <KeyboardArrowUpIcon />
                                              ) : (
                                                <KeyboardArrowDownIcon
                                                  onClick={async () => {
                                                    // console.log('hello I am in')
                                                    await ChildListing(
                                                      item.pre_print_mergename_id,
                                                      item.post_order,
                                                      item.merged_name,
                                                      item.batch_number,
                                                      item.file_name
                                                    );
                                                    setUploadCheck({
                                                      pre_print_mergename_id:
                                                        item.pre_print_mergename_id,
                                                      post_order:
                                                        item.post_order,
                                                      merged_name:
                                                        item.merged_name,
                                                      batch_number:
                                                        item.batch_number,
                                                    });
                                                    setUploadAssetsData({
                                                      ...uploadAssetsData,
                                                      post_order:
                                                        item.post_order,
                                                      batch_number:
                                                        item.batch_number,
                                                      file_name: item.file_name,
                                                    });
                                                  }}
                                                />
                                              )
                                            }
                                          </IconButton> */}
                                          {serialNumber(page, index)}
                                        </TableCell>
                                        <TableCell>{item?.merged_name}</TableCell>
                                        <TableCell component="th" scope="row">
                                          {item?.post_order}
                                        </TableCell>
                                        <TableCell>{item?.batch_number}</TableCell>
                                        <TableCell>
                                          <ReusableMenu
                                            APICall="yes"
                                            data={{
                                              state: {
                                                prePrintMergenameId: item.pre_print_mergename_id,
                                                post_order: item.post_order,
                                                batch_number: item.batch_number,
                                                merged_name: item.merged_name,
                                                file_name: item.file_name,
                                              },
                                            }}
                                            setMenuChecks={setMenuChecks}
                                            // height="180px"
                                            options={[
                                              // "Delete PO",
                                              'View RWO (PO)',
                                              'Merge RWO',
                                              'Export Merged RWO',
                                              // "Final Approve",
                                              // "Upload Comps",
                                              'Merge Comps',
                                              'Export Merged Comps',
                                              // "Upload Hanger",
                                              'Merge Hanger',
                                              'Export Merged Hanger',
                                              'Merge Rolled',
                                              'Export Merged Rolled',
                                            ]}
                                            YourSelection={getYourSelection(MenuChecks)}
                                            // YourSelection={MenuChecks.MergedRWOstatus === false ? ["View RWO (PO)", 'Merge RWO','Export Merged RWO']: MenuChecks.compsStatus === false ? ["Merge Comps","Export Merged Comps"]:MenuChecks.hangerStatus === false ? [ "Merge Hanger", "Export Merged Hanger"] :MenuChecks.Rolledstatus === false ? [ "Merge Rolled", "Export Merged Rolled"] :[]}
                                            handleOption={(option) => {
                                              handleClick(
                                                option,
                                                item.POSTER_CODE_1,
                                                item?.pre_print_mergename_id,
                                                item?.post_order,
                                                item.file_name,
                                                item.month,
                                                item.year,
                                                item.post_order,
                                                item.batch_number,
                                                item.merged_name
                                              );
                                            }}
                                          />

                                          <Popup
                                            title={`Are you sure you want to delete the record?`}
                                            open={deleteSubListing}
                                            // overflowY="auto"
                                            // height="365px"
                                            width={'430px'}
                                            content={
                                              <>
                                                <div
                                                  style={{
                                                    textAlign: 'center',
                                                    marginTop: 10,
                                                  }}
                                                >
                                                  <Button
                                                    size="large"
                                                    variant="contained"
                                                    style={{
                                                      background: '#C62828',
                                                      marginRight: '10px',
                                                    }}
                                                    onClick={() => setDeleteSubListing(false)}
                                                  >
                                                    Close
                                                  </Button>
                                                  <Button
                                                    size="large"
                                                    variant="contained"
                                                    style={{
                                                      background: '#1d213e',
                                                      marginRight: '15px',
                                                    }}
                                                    onClick={async () => {
                                                      //   console.log(
                                                      //     deleteSubListing_Data.batch_number
                                                      //   );
                                                      // }
                                                      await delete_SubListing(
                                                        deleteSubListing_Data.pre_print_mergename_id,
                                                        deleteSubListing_Data.post_order,
                                                        deleteSubListing_Data.merged_name,
                                                        deleteSubListing_Data.batch_number,
                                                        deleteSubListing_Data.file_name
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </Button>
                                                </div>
                                              </>
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell style={{ borderBottom: 0 }} colSpan={5}>
                                          <div className="subchild">
                                            <Collapse
                                              in={childRowExpansion[index]}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box sx={{ margin: 1 }}>
                                                <Typography
                                                  variant="h6"
                                                  gutterBottom
                                                  component="div"
                                                >
                                                  Sub Details
                                                </Typography>
                                                <div size="small" aria-label="purchases">
                                                  <Table className="dataTable">
                                                    <TableHead>
                                                      <TableRow key={index}>
                                                        <TableCell>Poster code</TableCell>
                                                        {/* <TableCell
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Actions
                                                        </TableCell> */}
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {childListingData.length > 0 ? (
                                                        childListingData.map((item, index) => (
                                                          <TableRow key={index}>
                                                            <TableCell>
                                                              {item.POSTER_CODE_1}
                                                            </TableCell>
                                                            {/* <TableCell
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <ReusableMenu
                                                                  APICall="no"
                                                                  options={[
                                                                    "Upload Assets",
                                                                    item.assets_status ===
                                                                    true
                                                                      ? "Add QR"
                                                                      : null,
                                                                    item.qr_status ===
                                                                    true
                                                                      ? "View QR Assets"
                                                                      : null,
                                                                    item.assets_status ===
                                                                      true &&
                                                                    item.qr_status ===
                                                                      true
                                                                      ? "Merge Printables"
                                                                      : null,
                                                                  ].filter(
                                                                    (option) =>
                                                                      option !==
                                                                      null
                                                                  )}
                                                                  YourSelection={
                                                                    item.final_qr_status ===
                                                                    true
                                                                      ? [
                                                                          "Add QR",
                                                                        ]
                                                                      : []
                                                                  }
                                                                  handleOption={(
                                                                    option
                                                                  ) => {
                                                                    handleClick(
                                                                      option,
                                                                      item.POSTER_CODE_1
                                                                    );
                                                                  }}
                                                                />
                                                              </TableCell> */}
                                                          </TableRow>
                                                        ))
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            style={{
                                                              textAlign: 'center',
                                                            }}
                                                            colSpan={3}
                                                          >
                                                            Record Not Found
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </div>
                                              </Box>
                                            </Collapse>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                                      Record Not Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={2}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Popup
        title={assetsData.length === 0 ? 'Please Upload Assets Here' : 'Edit Assets here'}
        open={uploadAsset}
        overflowY="auto"
        height="480px"
        content={
          <>
            <UploadAssets
              close={() => setUploadAsset(false)}
              setSnackbar={setSnackbar}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              uploadAssetsData={uploadAssetsData}
              setassetsData={setassetsData}
              ChildListing={ChildListing}
              SubListing={SubListing}
              uploadCheck={uploadCheck}
            />
          </>
        }
      />

      {/* Add QR Code Options */}
      <Popup
        title="Please enter the Details"
        open={addQR}
        overflowY="auto"
        height="480px"
        content={
          <>
            <Qr
              close={() => setAddQR(false)}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setSnackbar={setSnackbar}
              uploadAssetsData={uploadAssetsData}
              ChildListing={ChildListing}
              SubListing={SubListing}
              uploadCheck={uploadCheck}
            />
          </>
        }
      />

      <Popup
        title="Please enter the details"
        open={open1}
        overflowY="auto"
        // height="365px"
        content={
          <>
            <PrePrintForm
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              uploadAssetsData={uploadAssetsData}
              close={() => setOpen1(false)}
              value={getValues}
              setSnackbar={setSnackbar}
              printData={printData}
              apiCall="No"
              SubListing={SubListing}
              RWO_Type={RWO_Type}
              RowClose={() => {
                setChildRowExpansion([].fill(false));
                setRowExpansion(Array(showData.length).fill(false));
              }}
            />
          </>
        }
      />

      {showData?.length > 0 && next?.totalPages > 1 && (
        <Pagination
          count={next?.totalPages}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          className="paging"
          sx={{
            button: { mt: 2 },
            width: '100%',
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              disabled={
                (item.type === 'previous' && page === 1) ||
                // Disable the Next button if it's the last page
                (item.type === 'next' && page === next?.totalPageCount) ||
                // Optionally, disable the current selected page
                item.page === page
              }
            />
          )}
        />
      )}

      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default Preprint;
