const STAGING = "prod"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "jbvanss3ngqojxljkicbgy0ogjbphhke0w4ionvdvswlxb7c",
  dev: "jbvanss3ngqojxljkicbgy0ogjbphhke0w4ionvdvswlxb7c",
  prod: "u7yfvtopxk5arx6uqqgifnz6hdnyki8ynhwgdigaoldvbnrr",
  preview: "h9qtni21fz5qg5ijqetfg5scgho0uoum21vn5rahuf4jn8q8",
};

export const Keys = {
  EditorKey: KEY[STAGING] || "",
};
