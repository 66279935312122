export const MetaFields = [
  "Poster Code",
  "Template ID",
  "Column 1",
  "Column 2",
  "Column 3",
  "Column 4/ Article 1",
  "Column 4/ Article 2",
  "Column 4/ Article 3",
  "Column 4/ Article 4",
];

export const categories = {
  "1-COL-ICYMI-POLL": [
    "1-COL-ICYMI-POLL",
    // "1-ICYMI-POLL-NO-COL1-QR",
    // "1-ICYMI-POLL-NO-COL3-QR",
    // "1-ICYMI-POLL-NO-COL1-COL3-QR",
  ],
  "2-COL-ICYMI-POLL": ["2-COL-ICYMI-POLL",
    //  "2-ICYMI-POLL-NO-COL1-QR"
    ],
  "1-COL-ICYMI-CART": [
    "1-COL-ICYMI-CART",
    // "1-ICYMI-CARTOON-NO-COL1-QR",
    // "1-ICYMI-CARTOON-NO-COL3-QR",
    // "1-ICYMI-CARTOON-NO-COL1-COL3-QR",
  ],
  "	2-COL-ICYMI-CART": ["	2-COL-ICYMI-CART", 
    // "2-ICYMI-CARTOON-NO-COL1-QR"
  ],
  // "1-ICYMI": ["1-ICYMI"],
  // "2-ICYMI": ["2-ICYMI"],
  "1-COL-ICYMI-2-ADS": ["1-COL-ICYMI-2-ADS", 
    // "1-ICYMI-2AD-2A"
  ],
  // "2-ICYMI-2AD": ["2-ICYMI-2AD"],
};
