export const formatDateStringWithTime = (dateString) => {
  // Check if the input date string is valid
  if (!dateString) {
    throw new Error("Invalid date string");
  }

  // Parse the date string
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date string");
  }

  // Format the date to MM/dd/yyyy
  const formattedDate =
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2) +
    "/" +
    date.getFullYear();

  // Format the time to HH:mm:ss
  const formattedTime =
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);

  // Combine date and time
  const formattedDateTime = formattedDate + " " + formattedTime;

  return formattedDateTime;
};

export const replaceSymbols = (input) => {
  if (!input) {
    // Check for empty or null/undefined input
    return "";
  }
  return input.replace(/[\/]/g, "_");
};
