import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: '#fff',
  backgroundColor: '#364152',
  ...theme.applyStyles("dark", {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const GroupedAutocomplete = ({
  options,
  onChange,
  helperText,
  label,
  error,
  value,
  className,
  isOptionEqualToValue,
  height
}) => {
  const categorizedOptions = [];

  // Creating categorized options
  Object.keys(options).forEach((category) => {
    options[category].forEach((item) => {
      categorizedOptions.push({ category, title: item });
    });
  });

  return (
    <Autocomplete
      options={categorizedOptions.sort(
        (a, b) => -b.category.localeCompare(a.category)
      )}
      className={className}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.title === value.title} // Custom equality check
      onChange={onChange}
      value={value}
      sx={{ width: 200, height:height }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={error ? helperText : ""}
          sx={{
            '& .MuiAutocomplete-clearIndicator': {
              display: 'none',
            }}}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

export default GroupedAutocomplete;
